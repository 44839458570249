<template>
    <v-combobox
        :items="hours"
        class="hours-select"
        outline
        solo
        flat
        dense
        hide-details="auto"
        :value="value"
        :readonly="readonly || (hours && !hours.length)"
        :error="error"
        :search-input="search"
        @input="$emit('input', $event)"
    ></v-combobox>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        to: {
            type: String,
            default: null,
        },
        from: {
            type: String,
            default: null,
        },
        min: {
            type: String,
            default: null,
        },
        readonly: Boolean,
        error: Boolean,
    },
    data() {
        return {
            search: null,
        }
    },
    computed: {
        hours() {
            const hours = []

            for (let h = 0; h < 24; h++) {
                hours.push(`${h}:00`.padStart(5, '0'))
                hours.push(`${h}:30`.padStart(5, '0'))
            }

            if (this.search) {
                return hours.filter((hour) => {
                    return this.search < hour
                })
            } else {
                return hours.filter((hour) => {
                    return this.to
                        ? this.min
                            ? hour < this.to && hour > this.min
                            : hour < this.to
                        : this.from
                        ? hour > this.from
                        : true
                })
            }
        },
    },
    methods: {
        doSearch(val) {
            this.$emit('input', val)
            this.search = val
        },
    },
}
</script>

<style lang="scss">
.hours-select {
    width: 110px;
    display: inline-block;
}

// @TODO: redo without scoped sometime, readonly is unused atm

.hours-select.v-input--is-readonly .v-input__icon--append .v-icon {
    caret-color: rgba(0, 0, 0, 0.25) !important;
    color: rgba(0, 0, 0, 0.25) !important;
}

.hours-select.v-input--is-readonly.v-autocomplete.v-select.v-input--is-focused
    input {
    min-width: auto;
}

.hours-select input:read-only {
    color: rgba(0, 0, 0, 0.55) !important;
}
</style>
