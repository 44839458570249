<template>
    <div class="open-hours-list">
        <template v-for="day in days">
            <div :key="day" class="row">
                <template>
                    <div class="col-md-2 day-closed">
                        <v-checkbox
                            color="success"
                            :label="$t('days_of_week.' + day)"
                            :input-value="value[day] && value[day].length > 0"
                            hide-details
                            :disabled="!canEdit"
                            @change="$event ? add(day) : close(day)"
                        ></v-checkbox>
                    </div>
                </template>

                <div class="col-md-auto align-self-center">
                    <template v-if="value[day] && value[day].length === 0">
                        <div class="text-center">
                            <h3>{{ $t('labels.closed_all_day') }}</h3>
                        </div>
                    </template>
                    <template v-else>
                        <div v-for="(period, index) in value[day]" :key="index">
                            <hour-period-input
                                v-validate="{
                                    'period-hour-format': {},
                                    'period-from-lt-to': {},
                                    'period-overlap': {
                                        periods: value[day],
                                        index,
                                    },
                                }"
                                :value="period"
                                :values="value[day]"
                                :period-index="index"
                                :readonly="!canEdit"
                                height="10px"
                                class="d-inline-block day-hour-period"
                                :name="name + '-period-' + day + '-' + index"
                                :error-messages="
                                    errors.collect(
                                        name + '-period-' + day + '-' + index
                                    )
                                "
                                :data-vv-as="$t('labels.interval_quantity')"
                                data-vv-delay="500"
                                @input="update(day, index, $event)"
                            ></hour-period-input>
                            <v-btn
                                v-if="canEdit"
                                text
                                icon
                                color="error"
                                class="ma-0"
                                @click="remove(day, index)"
                            >
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </div>
                        <v-btn
                            v-if="canEdit"
                            dense
                            text
                            color="info"
                            @click="add(day)"
                        >
                            <v-icon>add_circle</v-icon>
                            <span class="oh-btn-text">Add hours</span>
                        </v-btn>
                    </template>
                </div>
            </div>

            <v-divider
                v-if="day < days.length - 1"
                :key="'divider' + day"
            ></v-divider>
        </template>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import HourPeriodInput from './HourPeriodInput'
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import isEmpty from 'lodash/isEmpty'

export default {
    components: {
        HourPeriodInput,
    },
    inject: {
        $validator: 'formValidator',
    },
    props: {
        value: {
            type: [Object, Array],
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        canEdit: Boolean,
    },
    data() {
        return {
            days: [0, 1, 2, 3, 4, 5, 6],
        }
    },
    computed: {
        ...mapGetters({
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
        }),
    },

    methods: {
        update: debounce(function (day, index, value) {
            const periods = cloneDeep(this.value)

            periods[day][index] = value

            this.$emit('input', periods)
        }, 100),
        remove(day, index) {
            const periods = cloneDeep(this.value)

            periods[day].splice(index, 1)

            this.$emit('input', periods)
        },
        close(day) {
            const periods = cloneDeep(this.value)

            periods[day] = []

            this.$emit('input', periods)
        },
        add(day) {
            let periods = cloneDeep(this.value)
            const currentDay = periods[day]

            if (currentDay?.length) {
                periods[day].push({
                    from: currentDay[currentDay.length - 1].to,
                    to: '23:30',
                })
            } else {
                if (!periods || isEmpty(periods))
                    periods = new Array(7).fill(0).map(() => new Array())

                periods[day].push({ from: '10:00', to: '20:00' })
            }
            this.$emit('input', periods)
        },
    },
}
</script>
