var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"open-hours-list"},[_vm._l((_vm.days),function(day){return [_c('div',{key:day,staticClass:"row"},[[_c('div',{staticClass:"col-md-2 day-closed"},[_c('v-checkbox',{attrs:{"color":"success","label":_vm.$t('days_of_week.' + day),"input-value":_vm.value[day] && _vm.value[day].length > 0,"hide-details":"","disabled":!_vm.canEdit},on:{"change":function($event){$event ? _vm.add(day) : _vm.close(day)}}})],1)],_c('div',{staticClass:"col-md-auto align-self-center"},[(_vm.value[day] && _vm.value[day].length === 0)?[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t('labels.closed_all_day')))])])]:[_vm._l((_vm.value[day]),function(period,index){return _c('div',{key:index},[_c('hour-period-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                                'period-hour-format': {},
                                'period-from-lt-to': {},
                                'period-overlap': {
                                    periods: _vm.value[day],
                                    index: index,
                                },
                            }),expression:"{\n                                'period-hour-format': {},\n                                'period-from-lt-to': {},\n                                'period-overlap': {\n                                    periods: value[day],\n                                    index,\n                                },\n                            }"}],staticClass:"d-inline-block day-hour-period",attrs:{"value":period,"values":_vm.value[day],"period-index":index,"readonly":!_vm.canEdit,"height":"10px","name":_vm.name + '-period-' + day + '-' + index,"error-messages":_vm.errors.collect(
                                    _vm.name + '-period-' + day + '-' + index
                                ),"data-vv-as":_vm.$t('labels.interval_quantity'),"data-vv-delay":"500"},on:{"input":function($event){return _vm.update(day, index, $event)}}}),(_vm.canEdit)?_c('v-btn',{staticClass:"ma-0",attrs:{"text":"","icon":"","color":"error"},on:{"click":function($event){return _vm.remove(day, index)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()],1)}),(_vm.canEdit)?_c('v-btn',{attrs:{"dense":"","text":"","color":"info"},on:{"click":function($event){return _vm.add(day)}}},[_c('v-icon',[_vm._v("add_circle")]),_c('span',{staticClass:"oh-btn-text"},[_vm._v("Add hours")])],1):_vm._e()]],2)],2),(day < _vm.days.length - 1)?_c('v-divider',{key:'divider' + day}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }