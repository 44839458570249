<template>
    <v-input :error-messages="errorMessages">
        <hour-input-widget
            :value="value.from"
            :to="value.to"
            :readonly="readonly"
            :error="errorMessages.length > 0"
            :min="min"
            @input="update($event, value.to)"
        ></hour-input-widget>
        &#8212;
        <hour-input-widget
            :value="value.to"
            :from="value.from"
            :readonly="readonly"
            :error="errorMessages.length > 0"
            @input="update(value.from, $event)"
        ></hour-input-widget>
    </v-input>
</template>

<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'
import HourInputWidget from './HourInputWidget.vue'

export default {
    components: { HourInputWidget },
    mixins: [ErrorMsgInputMixin],
    props: {
        value: {
            type: Object,
            default: null,
        },
        values: {
            type: Array,
            default: () => {
                return []
            },
        },
        periodIndex: {
            type: Number,
            default: null,
        },
        readonly: Boolean,
    },
    data() {
        return {
            min: null,
        }
    },
    watch: {
        values: {
            handler() {
                if (this.periodIndex && this.values[this.periodIndex - 1]) {
                    this.min = this.values[this.periodIndex - 1].to
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        update(from, to) {
            this.$emit('input', { from, to })
        },
    },
}
</script>
